import React, { useState } from "react";

import { useTheme } from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import Button from "../button";
import SideBar from "../sidebar";
import BasicMenu from "../dropDown";

import {
  ContentHeader,
  Container,
  ContentLogo,
  Logo,
  Navigation,
  ContentList,
  List,
  SideBarContainer,
} from "./styles";

import logo from "../../assets/logo_header.svg";
import { useTranslation } from "react-i18next";

const Header = ({ backgroundColor }) => {
  const theme = useTheme();

  const [sideBarShow, setSideBarShow] = useState(false);

  const navigate = useNavigate();

  const handleNavClick = (event, path) => {
    event.preventDefault();

    if (window.location.pathname !== path) {
      navigate(path);
    } else {
      const sectionId = event.target.getAttribute("href");
      const section = document.querySelector(sectionId);
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const showSideBar = () => {
    setSideBarShow(!sideBarShow);
  };

  const { t } = useTranslation();

  return (
    <ContentHeader style={{ backgroundColor: backgroundColor }}>
      <Container>
        <NavLink to={"/"}>
          <ContentLogo>
            <Logo alt="logo_ymk" src={logo} />
          </ContentLogo>
        </NavLink>

        <Navigation>
          <SideBarContainer>
            <FaBars onClick={showSideBar} color="white" />
            {sideBarShow && <SideBar active={setSideBarShow} />}
          </SideBarContainer>
          <ContentList>
            <List>
              <NavLink
                to={"/quem-somos"}
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  color: theme.colors.white,
                  fontFamily: `${theme.typography.title}, sans-serif`,
                  fontSize: "16px",
                }}
              >
                {t("translations.home.quem_somos")}
              </NavLink>
            </List>

            <List
              href="#business"
              onClick={(event) => handleNavClick(event, "/")}
            >
              {t("translations.home.unidades")}
            </List>
            <List href="#cases" onClick={(event) => handleNavClick(event, "/")}>
              {t("translations.home.cases")}
            </List>
            <List
              href="#contact"
              onClick={(event) => handleNavClick(event, "/")}
            >
              {t("translations.home.contato")}
            </List>
            <List>
              <Button
                width={170}
                color={theme.colors.subtitle_logos}
                background={theme.colors.white}
              >
                {t("translations.home.investidor")}
              </Button>
              <BasicMenu style={{ paddingLeft: "20px" }} />
            </List>
          </ContentList>
        </Navigation>
      </Container>
    </ContentHeader>
  );
};

export default Header;

import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useTranslation } from "react-i18next";

const BasicMenu = ({ style }) => {
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (data) => {
    const languageMap = {
      fr: "fr",
      "pt-BR": "pt-BR",
      ar: "ar",
      "en-US": "en-US",
      it: "it",
      "zh-CN": "zh-CN",
      es: "es",
    };

    const selectedLanguage = data.target.id;
    const languageCode = languageMap[selectedLanguage];

    if (languageCode) {
      i18n.changeLanguage(languageCode);
    }

    setAnchorEl(null);
  };

  return (
    <div style={style}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          height: "25px",
          borderRadius: "5px",
          color: "white",
          fontWeight: "bold",
        }}
      >
        {t("translations.home.idioma")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose} id="pt-BR">
          {t("translations.home.portugues")}
        </MenuItem>
        <MenuItem onClick={handleClose} id="en-US">
          {t("translations.home.ingles")}
        </MenuItem>
        <MenuItem onClick={handleClose} id="es">
          {t("translations.home.espanhol")}
        </MenuItem>
        <MenuItem onClick={handleClose} id="fr">
          {t("translations.home.frances")}
        </MenuItem>
        <MenuItem onClick={handleClose} id="zh-CN">
          {t("translations.home.chines")}
        </MenuItem>
        <MenuItem onClick={handleClose} id="ar">
          {t("translations.home.arabe")}
        </MenuItem>
        <MenuItem onClick={handleClose} id="it">
          {t("translations.home.italiano")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default BasicMenu;
